import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl'

import Layout from '../../../components/Layouts/layout'
import SEO from '../../../components/seo'
import Tile from '../../../components/Tile/Tile'
import Alert from '../../../components/Alert/Alert'

import Crane from '../../../svgs/craneHome.svg'
import Home from '../../../svgs/handsholdinghome.svg'

const ReserachEdPage = () => {
  const intl = useIntl()
  return (
    <Layout
      breadcrumb={{
        text: 'About',
        path: '/about/'
      }}
      header={
        <span className='bold'>Research &amp; Education: Resource Hub</span>
      }
    >
      <SEO lang={intl.locale} title='Research &amp; Education: Resource Hub' />
      <Container>
        {intl.locale === 'fr' && (
          <Row align='stretch'>
            <Col>
              <Alert header='Attention'>
                <p lang='fr'>
                  Pour les services en français, veuillez contacter le Service
                  des licences et à la clientèle de l’Office de réglementation
                  de la construction des logements au 416-487-HCRA (4272) ou{' '}
                  <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
                </p>
              </Alert>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <p>
              The HCRA has created distinct Resource Hubs to provide educational
              resources and information affecting new home buyers and builder
              qualifications through leading research and education.
            </p>
          </Col>
        </Row>

        <Row align='center'>
          <Col xl={6} md={6} sm={12}>
            <p>
              For resources that prepare builders for upcoming technical trends
              and best practices in home construction, visit our{' '}
              <Link to='/about/research-and-education-program/technical-research-and-education-resource-hub/'>
                Technical Research & Education Resource Hub
              </Link>
              :
            </p>
            <Tile
              icon={<Crane />}
              link={{
                text: 'Technical Research & Education Resource Hub',
                path:
                  '/about/research-and-education-program/technical-research-and-education-resource-hub/'
              }}
            />
          </Col>

          <Col xl={6} md={6} sm={12}>
            <p>
              For educational resources that support consumer protection,
              education, and awareness regarding homebuying and homeownership,
              visit our{' '}
              <Link to='/about/research-and-education-program/consumer-education-resource-hub/'>
                Consumer Education Resource Hub
              </Link>
              :
            </p>
            <Tile
              icon={<Home />}
              link={{
                text: 'Consumer Education Resource Hub',
                path:
                  '/about/research-and-education-program/consumer-education-resource-hub/'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ReserachEdPage
